import * as React from "react"
import Disqus from 'gatsby-plugin-disqus'
import AdSense from "../../components/adsense"
import AdSenseH from "../../components/adsense-horizontal"

const config = require('../../../gatsby-config');

export interface CommentsProps {
  id: string
  title: string
}
export const Comments: React.FC<CommentsProps> = props => {
  return (
    <div>
      <Disqus
        url={"https://gan0803.dev" + props.pathname}
        identifier={props.id}
        title ={props.title}
      />
    </div>
  )
}
