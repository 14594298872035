module.exports = {
  siteMetadata: {
    language: `ja`, // or `en`
    title: `gan0803.dev`,
    author: `Gan0803`,
    job: `Software Engineer (Android)`,
    keywords: [`software engineering`, `android`, `programming`, `gatsbyjs`, `gatsby`, `gadget`, `hobby`],
    heroText: `明日の自分のために`,
    description: `調べて学んだことをアウトプットしたり、趣味や気になる製品の情報を発信したりしていきます。`,
    siteUrl: `https://gan0803.dev`,
    social: {
      twitter: `Gan0803`,
    },
    algoliaSearch: false,
  },
  plugins: [
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-167119768-2",
      },
    },
    {
      resolve: "gatsby-crudzoo",
      options: {},
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: "明日の自分のために",
        short_name: "gan0803.dev",
        start_url: "/",
        background_color: "#ffea18",
        theme_color: "#5155c0",
        display: "minimal-ui",
        icon: "assets/favicon.png",
      },
    },
    {
      resolve: `gatsby-plugin-disqus`,
      options: {
          shortname: `gan0803`
      }
    },
  ],
}